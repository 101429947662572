<template>
	<div>
		<scNav navTag="scNews" :themeColor="themeColor"></scNav>
		<!-- 面包屑 -->
		<div style="max-width: 1440px;margin: 0 auto;">
			<crumbs :crumbs="['首页','新闻中心','新闻中心详情']" @clkItem="clkItem"></crumbs>
		</div>
		<div class="sc-width">
			<probm :probm="probm"></probm>
		</div>
		<bottom :bgColor="themeColor"></bottom>
	</div>
</template>

<script>
	import probm from "@/components/probm/probm"
	import scNav from '@/components/special/scNav'
	export default {
		components:{
			probm,
			scNav
		},
		created() {
			let type = this.$route.query.type
			let id = this.$route.query.id
			this.getZcDail(type,id)
			if(type==1) {
				// 专精特新版
				
				this.themeColor = "#386eb8"
			}else if(type==2) {
				// 科技创新版	
				this.themeColor = "#47a8c8"
			}else if(type==3) {
				// 交易版
				this.themeColor = "#d3a21e"
			}
		},
		data() {
			return {
				probm:{}
			}
		},
		methods:{
			// 查询政策详情
			getZcDail(type,id) {
				let url = ""
				if(type==1) {
					url = this.$api.base+"Specialization/getDetail"
				}else if(type==2) {
					url = this.$api.base+"Technology/getDetail"
				}else if(type==3) {
					url = this.$api.base+"Trading/getDetail"
				}
				let params = {
					id
				}
				this.$axios.get(
					url,{params}
				).then(res=>{
					if(res.code) {
						this.probm = res.data
					}
				})
			},
			// 点击面包屑
			clkItem(index) {
				let type = this.$route.query.type
				switch(index) {
					case 0:
						this.$router.push({name:"scIndex",query:{type}})
					break;
					case 1:
						this.$router.push({name:"scNews",query:{type}})
					break;
				}
			}
		}
	}
</script>

<style>
</style>